import { useState, useEffect } from 'react'
import axios from 'axios'

import './ModalPagamentos.css'

import Input from '../../Biblioteca/Input'
import Botao from '../../Biblioteca/Botao'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'

const ModalPagamento = ({ isOpen, onClose, pagamento }) => {
    const [search, setSearch] = useState()
    const [itens, setItens] = useState([])
    const [itensAdquiridos, setItensAdquiridos] = useState([])

    const fetchItensAdquiridos = async () => {
        if (!pagamento?._id) return

        try {
            const response = await axios.get(`/api/dal/itensAdquiridos/${pagamento._id}`)
            setItensAdquiridos(response.data.itensAdquiridos || [])
        } catch (error) {
            console.error('Erro ao buscar itens adquiridos:', error)
            alert('Ocorreu um erro ao buscar os itens adquiridos.')
        }
    }

    useEffect(() => {
        if (isOpen) {
            fetchItensAdquiridos()
        }
    }, [isOpen, pagamento])

    const updateSearch = (e) => {
        const { name, value } = e.target
        let tempState = { ...itens }
        tempState[name] = value
        setSearch(tempState)
    }

    const buscaItens = () => {
        axios.post('/api/pca/buscarItemDfdSead', search)
            .then(res => setItens(res.data))
            .catch(error => {
                alert(error.response.data)
                setItens([])
            })
    }
    const limparItens = () => { setItens([]) }

    const desassociarItem = async (idItem, qtdAdquirida) => {
        try {
            const payload = {
                idPagamento: pagamento._id,
                idItem,
                qtdAdquirida,
            }

            const response = await axios.post('/api/dal/desassociarItem', payload)

            alert("Item desassociado com sucesso!")
            fetchItensAdquiridos()
        } catch (error) {
            console.error("Erro ao desassociar item:", error)
            alert("Ocorreu um erro ao desassociar o item.")
        }
    }


    const adicionarAoPagamento = (index) => {
        const item = itens[index]
        if (!item.qtdAdquirida || item.qtdAdquirida <= 0) {
            alert("Defina uma quantidade adquirida válida para o item antes de adicionar.")
            return
        }

        const payload = {
            idPagamento: pagamento._id,
            idItem: item._id,
            qtdAdquirida: item.qtdAdquirida,
            objeto: item.objeto,
            descricao: item.descricao,
            anoPreenchimentoPca: item.anoPreenchimentoPca,
            fonte: item.fonte
        }

        axios.post('/api/dal/associarPagamento', payload)
            .then(response => {
                alert("Item associado ao pagamento com sucesso!")
                fetchItensAdquiridos()
                setItens([])
            })
            .catch(error => {
                console.error("Erro ao associar item ao pagamento:", error)
                alert("Ocorreu um erro ao associar o item ao pagamento.")
            })
    }

    if (!isOpen) return null
    return (
        <div className='modalPagamentos'>
            <button className='modalPagamentos-close-button'
                onClick={() => {
                    setItens([])
                    onClose()
                }}
            >
                X
            </button>
            <h4>Nº do Pagamento: {pagamento.apNumero} &nbsp;&nbsp;&nbsp;&nbsp;
                PBDOC: <a href={`https://pbdoc.pb.gov.br/sigaex/app/expediente/doc/exibir?sigla=${pagamento.numeroProcesso}`} target='_blank' >
                    {pagamento.numeroProcesso}
                </a></h4>
            <h4>Itens do PCA associados a este pagamento:</h4>
            <div className="modalPagamentos-content">
                <div className="modalPagamentos-table-container">
                    <table className="modalPagamentos-table">
                        <tr>
                            <th style={{ textAlign: "center", width: "10vw" }}>ITEM</th>
                            <th style={{ textAlign: "center", width: "25vw" }}>DESCRIÇÃO</th>
                            <th style={{ textAlign: "center" }}>ANO DE PREENCHIMENTO PCA</th>
                            <th style={{ textAlign: "center" }}>FONTE</th>
                            <th style={{ textAlign: "center" }}>QTD ADQUIRIDA</th>
                            <th style={{ textAlign: "center" }}>DESASSOCIAR</th>
                        </tr>
                        {itensAdquiridos.length > 0 ? (<>
                            <tbody>
                                {itensAdquiridos.map((el, index) => {
                                    const descricao = el.descricao.length > 200 ?
                                        el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
                                    return (
                                        <tr key={`itemAdquirido-${index}`}>
                                            <td style={{ textAlign: "center" }}>{el.objeto}</td>
                                            <td style={{ textAlign: "center" }}>{descricao}</td>
                                            <td style={{ textAlign: "center" }}>{el.anoPreenchimentoPca}</td>
                                            <td style={{ textAlign: "center" }}>{el.fonte}</td>
                                            <td style={{ textAlign: "center" }}>{el.qtdAdquirida}</td>
                                            <td className="tdNovoDfd" style={{ textAlign: "center" }}>
                                                <Botao
                                                    texto="DESASSOCIAR"
                                                    funcao={() => desassociarItem(el.idItem, el.qtdAdquirida)}
                                                    cor="#dc3545"
                                                    widthWeb="8vw"
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </>) : (<></>)}
                    </table>
                </div>

                <h4 style={{ marginTop: "2vh" }}>Associar itens do PCA a este pagamento:</h4>
                <Input texto="Buscar Item" nome="item" widthWeb="30vw" widthMobile="80vw" funcao={updateSearch} />
                <div>
                    <Botao texto="BUSCAR" funcao={buscaItens}
                        align="left" widthMobile="40vw" marginT="0.5vh" display="inline" />
                    <Botao texto="Limpar" funcao={limparItens} cor="#dc3545"
                        align="left" widthMobile="40vw" marginT="0.5vh" display="inline" />
                </div>

                {itens.length > 0 ? (<>
                    <div className="modalPagamentos-table-container">
                        <table className="modalPagamentos-table" style={{ margin: "2vh 0" }}>
                            <tr>
                                <th style={{ textAlign: "center", width: "10vw" }}>ITEM</th>
                                <th style={{ textAlign: "center", width: "25vw" }}>DESCRIÇÃO</th>
                                <th style={{ textAlign: "center" }}>SOLICITADO</th>
                                <th style={{ textAlign: "center" }}>VALOR</th>
                                <th style={{ textAlign: "center" }}>ANO DE PREENCHIMENTO PCA</th>
                                <th style={{ textAlign: "center" }}>FONTE</th>
                                <th style={{ textAlign: "center" }}>QTD ADQUIRIDA</th>
                                <th style={{ textAlign: "center" }}>ASSOCIAR AO PAGAMENTO</th>
                            </tr>
                            <tbody>
                                {itens.map((el, index) => {
                                    if (
                                        (pagamento.unidadeGestora === "230001 CORPO DE BOMBEIROS MILITAR" && el.fonte !== "CBM") ||
                                        (pagamento.unidadeGestora === "570001 FUNDO ESPECIAL DO CORPO DE BOMBEIROS" && el.fonte !== "FUNESBOM")
                                    ) {
                                        return null
                                    }

                                    const descricao = el.descricao.length > 200 ?
                                        el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
                                    return (
                                        <tr key={`item-${index}`}>
                                            <td style={{ textAlign: "center" }}>{el.objeto}</td>
                                            <td style={{ textAlign: "center" }}>{descricao}</td>
                                            <td style={{ textAlign: "center" }}>{el.qtdEstimada}</td>
                                            <td style={{ textAlign: "center" }}>{formatCurrency(el.qtdEstimada * el.valorEstimado)}</td>
                                            <td style={{ textAlign: "center" }}>{el.anoPreenchimentoPca}</td>
                                            <td style={{ textAlign: "center" }}>{el.fonte}</td>
                                            <td style={{ textAlign: "center" }}>
                                                <Input
                                                    nome="qtdAdquirida"
                                                    tipo="number"
                                                    valor={el.qtdAdquirida || 0}
                                                    widthWeb="100%"
                                                    funcao={(e) => {
                                                        const updatedItens = itens.map((it, i) =>
                                                            i === index ? { ...it, qtdAdquirida: Number(e.target.value) } : it
                                                        )
                                                        setItens(updatedItens)
                                                    }}
                                                />
                                            </td>
                                            <td className="tdNovoDfd">
                                                <Botao
                                                    texto="Adicionar"
                                                    funcao={() => adicionarAoPagamento(index)}
                                                    widthWeb="6vw"
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>) : (<></>)}
            </div>
        </div>
    )
}

export default ModalPagamento
