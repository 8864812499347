import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'

import Senha from '../Senha/Senha'
//CPL
import Processos from "../Cpl/Processos/Processos"
import InicioCpl from '../Cpl/InicioCpl'
import Documentos from '../Cpl/Documentos/Documentos'
import Aditivos from '../Cpl/Aditivos/Aditivos'
//DF
import Continuados from '../DF/Continuados'
import NovaDiaria from '../DF/NovaDiaria'
//EisenhowerMatrix
import EisenhowerMatrix from '../EisenhowerMatrix/EisenhowerMatrix'
import ChatAI from '../EisenhowerMatrix/ChatAI'
import ConversationsHistory from '../EisenhowerMatrix/ConversationsHistory'
import FormEM6 from '../EisenhowerMatrix/FormEM6'
import FormEM6regionais from '../EisenhowerMatrix/FormEM6regionais'
import TestSocket from '../EisenhowerMatrix/TestSocket'
import TabelasPorAreas from '../EisenhowerMatrix/TabelasPorAreas'
import RegionaisPorArea from '../EisenhowerMatrix/RegionaisPorArea'
import TabelaOperacionais from '../EisenhowerMatrix/TabelaOperacionais'
import Dfd from '../EisenhowerMatrix/Dfd'
import DfdRegional from '../EisenhowerMatrix/DfdRegional'
import AddAdministrativo from '../EisenhowerMatrix/AddAdministrativo'
import ItensSemValor from '../EisenhowerMatrix/ItensSemValor'
import ImpressaoDfd from '../EisenhowerMatrix/ImpressaoDfd'
import UserUpdateItens from '../EisenhowerMatrix/UserUpdateItens'
import ItensCalculo from '../EisenhowerMatrix/ItensCalculo'
import TabelaCod from '../EisenhowerMatrix/TabelaCod'
import TabelaCatMat from '../EisenhowerMatrix/TabelaCatMat'
import TabelaPreenchimentoCentral from '../EisenhowerMatrix/TabelaPreenchimentoCentral'
import DFDsSelecionaveis from '../EisenhowerMatrix/DFDsSelecionaveis'
import DetalhamentoDespesa from '../EisenhowerMatrix/DetalhamentoDespesa'
import TabelaFinal from '../EisenhowerMatrix/TabelaFinal'
import InsertVectors from '../Vectors/InsertVectors'
import NovoDed from '../EisenhowerMatrix/NovoDed'
import PesquisaDeds from '../EisenhowerMatrix/PesquisaDeds'
import DedView from '../EisenhowerMatrix/DedView'
import ImpressaoDfdGeral from '../EisenhowerMatrix/ImpressaoDfdGeral'
import User from '../User/User'

// PCA 2024
import CplDal from '../PCA/CplDal'
import SolicitarItem from '../PCA/NovosItens/SolicitarItem'
import AprovarItem from '../PCA/NovosItens/AprovarItem'
import NovoDfd from '../PCA/Dfd/NovoDfd'
import RegionalFonte from '../PCA/Dfd/RegionalFonte'
import SetAno from '../PCA/Impressoes/SetAno'
import SetAnoGeral from '../PCA/Impressoes/SetAnoGeral'
import Dashboard from '../PCA/Dashboard/Dashboard'
import CatsAgrupados from '../PCA/Helpers/CatsAgrupados'
import TodosItens from '../PCA/Helpers/TodosItens'
import TabelaSead from '../PCA/Helpers/TabelaSead'
import TabelaSeadSetor from '../PCA/Helpers/TabelaSeadSetor'
import UpdatedItens from '../PCA/Helpers/UpdatedItens'
import BloquearLiberarDfd from '../PCA/Dfd/BloquearLiberarDfd'
import DfdBloqueado from '../PCA/Dfd/DfdBloqueado'
import EscolhaItens from '../PCA/Versionamento/EscolhaItens'
import TabelaEtp from '../PCA/Helpers/TabelaEtp'
import DetalhamentoDespesaPca from '../PCA/Helpers/DetalhamentoDespesaPca'
import SnaPcaGraph from '../PCA/SNA/SnaPcaGraph'
import SelecionarModelo from '../PCA/DFD SEAD/SelecionarModelo'
import DfdSeadView from '../PCA/DFD SEAD/DfdSeadView'
import BuscaDfdSead from '../PCA/DFD SEAD/BuscaDfdSead'
import NovoContrato from '../DAL/Contratos/NovoContrato'
import BuscaContratos from '../DAL/Contratos/BuscaContratos'
import LogEdicoes from '../DAL/Contratos/LogEdicoes'
import TabelaGestaoItens from '../DAL/GestaoItens/TabelaGestaoItens'
import DfdExtra from '../PCA/DfdExtra/DfdExtra'
import GetDfdExtra from '../PCA/DfdExtra/GetDfdExtra'
import DfdExtraView from '../PCA/DfdExtra/DfdExtraView'
import Empenhos from '../DAL/Empenhos/Empenhos'
import Pagamentos from '../DAL/Pagamentos/Pagamentos'
import GerenciaUnidades from '../PCA/Helpers/GerenciaUnidades'

// EM2
import Tarefas from '../EM2/Tarefas'

const Content = () => {
    const [situacaoPreenchimento, setSituacao] = useState("")
    const [anoPreenchimento, setAno] = useState("")

    useEffect(() => {
        axios.get('/api/pca/getSituacaoPreenchimento')
            .then(res => {
                setSituacao(res.data.status)
                setAno(res.data.ano)
            })
    }, [])

    return (
        <Routes>
            {RenderContent()}
            <Route exact path='/senha' element={<Senha />} />
        </Routes>
    )

    function RenderContent() {
        if (localStorage.tipo === "interno") {
            return (
                <>
                    <Route exact path='/' element={<InicioCpl />} />
                    <Route exact path='/processosCpl' element={<Processos />} />
                    <Route exact path='/documentosCpl' element={<Documentos />} />
                    <Route exact path='/aditivosCpl' element={<Aditivos />} />
                    <Route exact path='/continuadosDf' element={<Continuados />} />
                    <Route exact path='/novaDiaria' element={<NovaDiaria />} />
                    <Route exact path='/eisenhowerMatrix' element={<EisenhowerMatrix />} />
                    <Route exact path='/chatAI' element={<ChatAI />} />
                    <Route exact path='/chatAI/:id' element={<ChatAI />} />
                    <Route exact path='/conversationsHistory' element={<ConversationsHistory />} />
                    {/* <Route exact path='/formEM6' element={<FormEM6 />} /> */}
                    <Route exact path='/formEM6regionais' element={<FormEM6regionais />} />
                    <Route exact path='/testSocket' element={<TestSocket />} />
                    <Route exact path='/tabelasPorAreas' element={<TabelasPorAreas />} />
                    <Route exact path='/regionaisPorArea/:regional' element={<RegionaisPorArea />} />
                    <Route exact path='/tabelaOperacionais' element={<TabelaOperacionais />} />
                    <Route exact path='/dfd' element={<DfdRegional />} />
                    <Route exact path='/addAdministrativo' element={<AddAdministrativo />} />
                    <Route exact path='/itensSemValor' element={<ItensSemValor />} />
                    <Route exact path='/impressaoDfd' element={<ImpressaoDfd />} />
                    {/* <Route exact path='/userUpdateItens' element={<UserUpdateItens />} /> */}
                    <Route exact path='/itensCalculo' element={<ItensCalculo />} />
                    <Route exact path='/tabelaCod' element={<TabelaCod />} />
                    <Route exact path='/tabelaCatMat' element={<TabelaCatMat />} />
                    <Route exact path='/tabelaPreenchimentoCentral' element={<TabelaPreenchimentoCentral />} />
                    <Route exact path='/DFDsSelecionaveis' element={<DFDsSelecionaveis />} />
                    <Route exact path='/detalhamentoDespesa' element={<DetalhamentoDespesa />} />
                    <Route exact path='/tabelaFinal' element={<TabelaFinal />} />
                    <Route exact path='/insertVectors' element={<InsertVectors />} />
                    <Route exact path='/novoDed' element={<NovoDed />} />
                    <Route exact path='/dedView/:id' element={<DedView />} />
                    <Route exact path='/pesquisaDeds' element={<PesquisaDeds />} />
                    <Route exact path='/CplDal' element={<CplDal />} />
                    <Route exact path='/ImpressaoDfdGeral' element={<ImpressaoDfdGeral />} />
                    <Route exact path='/SolicitarItem' element={<SolicitarItem />} />
                    <Route exact path='/AprovarItem' element={<AprovarItem />} />
                    <Route exact path='/NovoDfd' element={situacaoPreenchimento === "Liberado" ? <NovoDfd year={anoPreenchimento}/> : <DfdBloqueado />} />
                    <Route exact path='/RegionalFonte' element={<RegionalFonte />} />
                    <Route exact path='/SetAno' element={<SetAno />} />
                    <Route exact path='/SetAnoGeral' element={<SetAnoGeral />} />
                    <Route exact path='/Dashboard' element={<Dashboard />} />
                    <Route exact path='/CatsAgrupados' element={<CatsAgrupados />} />
                    <Route exact path='/TodosItens' element={<TodosItens />} />
                    <Route exact path='/TabelaSead' element={<TabelaSead />} />
                    <Route exact path='/UpdatedItens' element={<UpdatedItens />} />
                    <Route exact path='/BloquearLiberarDfd' element={<BloquearLiberarDfd />} />
                    <Route exact path='/Tarefas' element={<Tarefas />} />
                    <Route exact path='/EscolhaItens' element={<EscolhaItens />} />
                    <Route exact path='/TabelaEtp' element={<TabelaEtp />} />
                    <Route exact path='/DetalhamentoDespesaPca' element={<DetalhamentoDespesaPca />} />
                    <Route exact path='/SnaPcaGraph' element={<SnaPcaGraph />} />
                    <Route exact path='/SelecionarModelo' element={<SelecionarModelo />} />
                    <Route exact path='/SelecionarModelo/:id' element={<SelecionarModelo />} />
                    <Route exact path='/DfdSeadView/:id' element={<DfdSeadView />} />
                    <Route exact path='/BuscaDfdSead' element={<BuscaDfdSead />} />
                    <Route exact path='/User' element={<User />} />
                    <Route exact path='/NovoContrato' element={<NovoContrato />} />
                    <Route exact path='/BuscaContratos' element={<BuscaContratos />} />
                    <Route exact path='/NovoContrato/:id' element={<NovoContrato />} />
                    <Route exact path='/LogEdicoes' element={<LogEdicoes />} />
                    <Route exact path='/TabelaGestaoItens' element={<TabelaGestaoItens />} />
                    <Route exact path='/DfdExtra' element={<DfdExtra />} />
                    <Route exact path='/GetDfdExtra' element={<GetDfdExtra />} />
                    <Route exact path='/DfdExtraView/:id' element={<DfdExtraView />} />
                    <Route exact path='/Empenhos' element={<Empenhos />} />
                    <Route exact path='/Pagamentos' element={<Pagamentos />} />
                    <Route exact path='/GerenciaUnidades' element={<GerenciaUnidades year={anoPreenchimento}/>} />
                </>
            )
        }
        if (localStorage.tipo === "geral") {
            return (
                <>
                    <Route exact path='/chatAI' element={<ChatAI />} />
                    <Route exact path='/chatAI/:id' element={<ChatAI />} />
                    <Route exact path='/conversationsHistory' element={<ConversationsHistory />} />
                    <Route exact path='/tabelasPorAreas' element={<TabelasPorAreas />} />
                    <Route exact path='/regionaisPorArea/:regional' element={<RegionaisPorArea />} />
                    <Route exact path='/tabelaOperacionais' element={<TabelaOperacionais />} />
                    <Route exact path='/dfd' element={<DfdRegional />} />
                    <Route exact path='/itensSemValor' element={<ItensSemValor />} />
                    <Route exact path='/impressaoDfd' element={<ImpressaoDfd />} />
                    {/* <Route exact path='/userUpdateItens' element={<UserUpdateItens />} /> */}
                    <Route exact path='/itensCalculo' element={<ItensCalculo />} />
                    <Route exact path='/tabelaCod' element={<TabelaCod />} />
                    <Route exact path='/tabelaPreenchimentoCentral' element={<TabelaPreenchimentoCentral />} />
                    <Route exact path='/DFDsSelecionaveis' element={<DFDsSelecionaveis />} />
                    <Route exact path='/detalhamentoDespesa' element={<DetalhamentoDespesa />} />
                    <Route exact path='/tabelaFinal' element={<TabelaFinal />} />
                    <Route exact path='/novoDed' element={<NovoDed />} />
                    <Route exact path='/dedView/:id' element={<DedView />} />
                    <Route exact path='/pesquisaDeds' element={<PesquisaDeds />} />
                    <Route exact path='/SolicitarItem' element={<SolicitarItem />} />
                    <Route exact path='/AprovarItem' element={<AprovarItem />} />
                    <Route exact path='/NovoDfd' element={situacaoPreenchimento === "Liberado" ? <NovoDfd year={anoPreenchimento}/> : <DfdBloqueado />} />
                    <Route exact path='/' element={<RegionalFonte />} />
                    <Route exact path='/regionalFonte' element={<RegionalFonte />} />
                    <Route exact path='/CplDal' element={<CplDal />} />
                    <Route exact path='/SetAno' element={<SetAno />} />
                    <Route exact path='/SetAnoGeral' element={<SetAnoGeral />} />
                    <Route exact path='/Dashboard' element={<Dashboard />} />
                    <Route exact path='/TodosItens' element={<TodosItens />} />
                    <Route exact path='/CatsAgrupados' element={<CatsAgrupados />} />
                    <Route exact path='/TabelaSead' element={<TabelaSead />} />
                    <Route exact path='/UpdatedItens' element={<UpdatedItens />} />
                    <Route exact path='/BloquearLiberarDfd' element={<BloquearLiberarDfd />} />
                    <Route exact path='/EscolhaItens' element={<EscolhaItens />} />
                    <Route exact path='/TabelaEtp' element={<TabelaEtp />} />
                    <Route exact path='/DetalhamentoDespesaPca' element={<DetalhamentoDespesaPca />} />
                    <Route exact path='/SelecionarModelo' element={<SelecionarModelo />} />
                    <Route exact path='/SelecionarModelo/:id' element={<SelecionarModelo />} />
                    <Route exact path='/DfdSeadView/:id' element={<DfdSeadView />} />
                    <Route exact path='/BuscaDfdSead' element={<BuscaDfdSead />} />
                    <Route exact path='/User' element={<User />} />
                    <Route exact path='/TabelaGestaoItens' element={<TabelaGestaoItens />} />
                    <Route exact path='/DfdExtra' element={<DfdExtra />} />
                    <Route exact path='/GetDfdExtra' element={<GetDfdExtra />} />
                    <Route exact path='/DfdExtraView/:id' element={<DfdExtraView />} />
                    <Route exact path='/Empenhos' element={<Empenhos />} />
                    <Route exact path='/Pagamentos' element={<Pagamentos />} />
                </>
            )
        }
        if (localStorage.tipo === "cpl") {
            return (
                <>
                    <Route exact path='/' element={<InicioCpl />} />
                    {/* <Route exact path='/dashboard' element={<Dashboard />} />
                    <Route exact path='/processosCpl' element={<Processos />} />
                    <Route exact path='/documentosCpl' element={<Documentos />} />
                    <Route exact path='/aditivosCpl' element={<Aditivos />} /> */}
                    {/* <Route exact path='/tabelaCatMat' element={<TabelaCatMat />} /> */}
                    <Route exact path='/CplDal' element={<CplDal />} />
                </>
            )
        }
        else if (localStorage.tipo === "df") {
            return (
                <>
                    <Route exact path='/continuadosDf' element={<Continuados />} />
                    <Route exact path='/novaDiaria' element={<NovaDiaria />} />
                </>
            )
        }
        else if (localStorage.tipo === "dfd") {
            return (
                <>
                    <Route exact path='/' element={situacaoPreenchimento === "Liberado" ? <NovoDfd year={anoPreenchimento}/> : <DfdBloqueado />} />
                    <Route exact path='/SolicitarItem' element={<SolicitarItem />} />
                    <Route exact path='/SetAno' element={<SetAno />} />
                    <Route exact path='/CatsAgrupados' element={<CatsAgrupados />} />
                    <Route exact path='/TabelaSeadSetor' element={<TabelaSeadSetor />} />
                    <Route exact path='/DfdExtra' element={<DfdExtra />} />
                    <Route exact path='/GetDfdExtra' element={<GetDfdExtra />} />
                    <Route exact path='/DfdExtraView/:id' element={<DfdExtraView />} />
                    {/* <Route exact path='/impressaoDfd' element={<ImpressaoDfd />} />
                    <Route exact path='/itensCalculo' element={<ItensCalculo />} /> */}
                </>
            )
        }
        else if (localStorage.tipo === "centralDeCompras") {
            return (
                <>
                    <Route exact path='/' element={<TabelaCod />} />
                </>
            )
        }
        else if (localStorage.tipo === "EM2") {
            return (
                <>
                    <Route exact path='/' element={<Tarefas />} />
                    <Route exact path='/tarefas' element={<Tarefas />} />
                </>
            )
        }
        else if (localStorage.tipo === "dal") {
            return (
                <>
                    <Route exact path='/SelecionarModelo' element={<SelecionarModelo />} />
                    <Route exact path='/SelecionarModelo/:id' element={<SelecionarModelo />} />
                    <Route exact path='/DfdSeadView/:id' element={<DfdSeadView />} />
                    <Route exact path='/BuscaDfdSead' element={<BuscaDfdSead />} />
                    <Route exact path='/SetAnoGeral' element={<SetAnoGeral />} />
                    <Route exact path='/TodosItens' element={<TodosItens />} />
                    <Route exact path='/CatsAgrupados' element={<CatsAgrupados />} />
                    <Route exact path='/TabelaSead' element={<TabelaSead />} />
                    <Route exact path='/TabelaEtp' element={<TabelaEtp />} />
                    <Route exact path='/DetalhamentoDespesaPca' element={<DetalhamentoDespesaPca />} />
                    <Route exact path='/NovoContrato' element={<NovoContrato />} />
                    <Route exact path='/BuscaContratos' element={<BuscaContratos />} />
                    <Route exact path='/NovoContrato/:id' element={<NovoContrato />} />
                    <Route exact path='/LogEdicoes' element={<LogEdicoes />} />
                    <Route exact path='/TabelaGestaoItens' element={<TabelaGestaoItens />} />
                    <Route exact path='/Empenhos' element={<Empenhos />} />
                    <Route exact path='/Pagamentos' element={<Pagamentos />} />
                </>
            )
        }
    }
}

export default Content