import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "./MyContext"
import Moeda from '../Biblioteca/Funcoes/moeda'
import Cnpj from '../Biblioteca/Funcoes/cnpj'

const Input = (props) => {
    const { myState } = useContext(MyContext);
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const width = mediaWeb ? props.widthWeb : props.widthMobile

    const estilo = {
        divInput: {
            display: props.display || "inline-grid", textAlign: "center", width: width || "15vw",
            margin: "auto", marginBottom: props.marginB || "1vh",
            marginRight: props.marginR || "2vw", marginLeft: props.marginL
        },
        label: {
            textAlign: "left", fontWeight: 600, fontSize: props.fontS || 15,
            color: myState === "light" ? "black" : "#dbdbdb"
        },
        input: {
            width: width || "15vw", border: myState === "light" ? "1px solid #00000029" : "1px solid rgb(0,0,0, 0.35)",
            borderRadius: "5px", color: myState === "light" ? "black" : "#dbdbdb",
            minHeight: "18px", fontSize: props.fontS || 15, backgroundColor: "#00000000"
        }
    }

    return (
        <div style={estilo.divInput}>
            <div style={estilo.label}>{props.texto}</div>
            {RenderInput()}
        </div>
    )

    function formatCurrency(value) {
        if (typeof value !== 'number' || isNaN(value)) {
            return "R$ 0,00"
        }
        const options = {
            style: 'currency',
            currency: 'BRL'
        }
        return value.toLocaleString('pt-BR', options)
    }



    function RenderInput() {
        const handleChange = (e) => {
            if (props.funcao) {
                props.funcao(e, props.additionalArgs)
            }
        }

        if (props.modo === "fixed") {
            if (props.tipo === "date" && props.valor) {
                let ano = props.valor.slice(0, 4)
                let mes = props.valor.slice(5, 7)
                let dia = props.valor.slice(8, 10)
                var data = dia + "/" + mes + "/" + ano
            } else var data = props.valor
            return (
                <div style={{ ...estilo.input, textAlign: "left" }}>
                    {data}
                </div>
            )
        }
        if (props.tipo === "moeda") {
            return (
                <input style={estilo.input}
                    onChange={e => Moeda(e, handleChange)} name={props.nome}
                    placeholder="R$ 00.000,00"
                    defaultValue={formatCurrency(props.valor) || null}
                />
            )
        }
        if (props.tipo === "cnpj") {
            return (
                <input style={estilo.input}
                    onChange={e => Cnpj(e, handleChange)} name={props.nome}
                    placeholder="00.000.000/0000-00"
                    defaultValue={props.valor || null}
                />
            )
        } else {
            return (
                <input style={estilo.input} type={props.tipo}
                    onChange={handleChange} name={props.nome} placeholder={props.placeholder}
                    defaultValue={props.valor || null}
                />
            )
        }
    }
}

export default Input