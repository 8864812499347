import { useState, useEffect } from 'react'
import axios from 'axios'

import formatCurrency from "../../Biblioteca/Funcoes/formatCurrency"
import Caixa from '../../Biblioteca/Caixa'
import Input from '../../Biblioteca/Input'
import Botao from '../../Biblioteca/Botao'

const GerenciaUnidades = ({ year }) => {
    const [regionalUser, setRegionalUser] = useState("")
    const [fonte, setFonte] = useState("")
    const [saldoDisponivel, setSaldoDisponivel] = useState("")
    const [subordinadas, setSubordinadas] = useState([])
    const [novaUnidade, setNovaUnidade] = useState({})

    useEffect(() => {
        if (!year) return
        axios
            .post('/api/pca/getUnidadeUser', { anoPreenchimento: year })
            .then((res) => {
                setRegionalUser(res.data.regionalUser)
                setFonte(res.data.fonte)
                setSaldoDisponivel(res.data.saldoDisponivel)
                setSubordinadas(res.data.subordinadas)
            })
            .catch((error) => alert(error.response.data.message))
    }, [year])

    const updateNovaUnidade = (e) => {
        const { name, value } = e.target
        setNovaUnidade(prev => ({ ...prev, [name]: value }))
    }

    const updateSubordinada = (index, field, value) => {
        setSubordinadas(prev => {
            const updated = [...prev]
            updated[index] = { ...updated[index], [field]: value }
            return updated
        })
    }

    const editarUnidade = (index) => {
        const unidade = subordinadas[index]
        axios.put(`/api/pca/updateUnidade/${unidade._id}`, unidade)
            .then(() => {
                alert("Unidade atualizada com sucesso!")
                window.location = "/GerenciaUnidades"
            })
            .catch((error) => alert(error.response.data.message))
    }

    const excluirUnidade = (id) => {
        if (window.confirm("Tem certeza que deseja excluir esta unidade?")) {
            axios.delete(`/api/pca/deleteUnidade/${id}`)
                .then(() => {
                    alert("Unidade excluída com sucesso!")
                    window.location = "/GerenciaUnidades"
                })
                .catch((error) => alert(error.response.data.message))
        }
    }

    const saveNovaUnidade = () => {
        axios.post('/api/pca/createNovaUnidade', { ...novaUnidade, anoPreenchimento: year })
            .then(res => {
                alert(res.data)
                window.location.reload()
            })
            .catch((error) => alert(error.response.data.message))
    }
console.log(subordinadas)
    return (
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '1vh',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '22vw',
                height: '6vw',
                border: '1px solid #ddd',
                borderRadius: '8px',
            }}>
                {saldoDisponivel ? (
                    <div className="internoSaldo">
                        <div className="regionalSaldo">{regionalUser}({fonte})</div>
                        {saldoDisponivel ? <>{formatCurrency(saldoDisponivel)}</> : <>R$ 0,00</>}
                    </div>
                ) : (
                    <div className="internoSaldo">Carregando saldo...</div>
                )}
            </div>
            {subordinadas.length > 0 && (
                <table style={{ margin: "4vh auto 2vh auto", width: "97vw" }}>
                    <thead>
                        <tr>
                            <th style={{ width: "25vw" }}>UNIDADE</th>
                            <th style={{ width: "25vw" }}>SALDO DEFINIDO</th>
                            <th style={{ width: "25vw" }}>SALDO UTILIZADO</th>
                            <th style={{ width: "11vw" }}>SALVAR</th>
                            <th style={{ width: "11vw" }}>EXCLUIR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subordinadas.map((el, index) => (
                            <tr key={el._id}>
                                <td>
                                    <Input
                                        nome="regionalDepartamento"
                                        widthWeb="23vw"
                                        valor={el.regionalDepartamento}
                                        funcao={(e) => updateSubordinada(index, 'regionalDepartamento', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <Input
                                        nome="saldoDefinido"
                                        widthWeb="23vw"
                                        tipo="moeda"
                                        valor={el.saldoDefinido}
                                        funcao={(e) => updateSubordinada(index, 'saldoDefinido', e.target.value)}
                                    />
                                </td>
                                <td>{formatCurrency(el.saldoUtilizado)}</td>
                                <td>
                                    <Botao
                                        texto="EDITAR"
                                        funcao={() => editarUnidade(index)}
                                        align="left"
                                        widthMobile="40vw"
                                        display="inline-grid"
                                    />
                                </td>
                                <td>
                                    <Botao
                                        texto="EXCLUIR"
                                        cor="red"
                                        funcao={() => excluirUnidade(el._id)}
                                        align="left"
                                        widthMobile="40vw"
                                        display="inline-grid"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <Caixa widthWeb="97vw">
                <div style={{ textAlign: "center", fontWeight: 600, color: "#33348F" }}>INSERIR NOVA UNIDADE SUBORDINADA</div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Input
                        texto="Unidade"
                        nome="regionalDepartamento"
                        widthWeb="30vw"
                        valor={novaUnidade.regionalDepartamento || ''}
                        funcao={updateNovaUnidade}
                    />
                    <Input
                        texto="Saldo Definido"
                        nome="saldoDefinido"
                        widthWeb="30vw"
                        tipo="moeda"
                        valor={novaUnidade.saldoDefinido || ''}
                        funcao={updateNovaUnidade}
                    />
                    <Botao
                        texto="SALVAR"
                        funcao={saveNovaUnidade}
                        align="left"
                        widthMobile="40vw"
                        display="inline-grid"
                    />
                </div>
            </Caixa>
        </>
    )
}

export default GerenciaUnidades
